import React from 'react';
import {
  Card,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppAnimate from '../../../@crema/core/AppAnimate';
import TextField from '@mui/material/TextField';
import {Fonts} from '../../../shared/constants/AppEnums';
import {LoadingButton} from '@mui/lab';
import CustomForm from './components/CustomForm/CustomForm';
import TrackboxForm from './components/TrackboxForm/TrackBoxForm';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';
import DynamicFormFromURL from './components/CustomUrlForm/DynamicFormFromURL';

const AddClientUI = ({
  setIntegration_type,
  integration_type,
  description,
  handleChange,
  handleSubmit,
  handleDomainSelect,
  isAdding,
  setCurlCode,
  setUrl,
  urlCode,
  dominId,
  setHttpMethod,
  httpMethod,
  handleChangeTrackboxData,
  openConfirmModal,
  handleCloseModal,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <AppAnimate animation='transition.slideUpIn' delay={200}>
        <Card>
          <CardHeader
            sx={{
              py: 4,
              pb: 1,
              px: 5,
              display: 'flex',
              alignItems: 'center',
              minHeight: 50,
              boxSizing: 'border-box',
              '& .MuiTypography-h5': {
                fontSize: 14,
                fontWeight: Fonts.BOLD,
                marginBottom: 0.25,
              },
            }}
            // title={title}
            subheader={description}
            root={{
              subheader: {
                fontSize: 13,
              },
            }}
          />

          <CardContent sx={{px: 10, pt: 1}}>
            <Grid container spacing={4} textAlign={'start'}>
              <Grid item textAlign={'start'} xs={12} lg={6}>
                <Box>
                  <>
                    <Typography>User Name*</Typography>
                    <TextField
                      id='user_name'
                      name='user_name'
                      variant='outlined'
                      onChange={handleChange}
                      required
                    />
                  </>
                </Box>
              </Grid>
              {integration_type == 'trackbox' && (
                <Grid item textAlign={'start'} xs={12} lg={6}>
                  <Box>
                    <>
                      <Typography>Integration URL*</Typography>

                      <TextField
                        fullWidth
                        id='integration_url'
                        name='integration_url'
                        variant='outlined'
                        onChange={handleChange}
                        required
                      />
                    </>
                  </Box>
                </Grid>
              )}
              <Grid item textAlign={'start'} xs={9} lg={9}>
                <Box>
                  <FormControl>
                    <FormLabel id='integration_type'>
                      integration type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      defaultValue='trackbox'
                      name='radio-buttons-group'
                      onChange={(e) => {
                        setIntegration_type(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value='trackbox'
                        control={<Radio />}
                        label='trackbox'
                      />
                      <FormControlLabel
                        value='customCurl'
                        control={<Radio />}
                        label='custom Curl'
                      />
                      <FormControlLabel
                        value='customUrl'
                        control={<Radio />}
                        label='custom Url'
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Grid item>
                  <Autocomplete
                    disablePortal
                    id='combo-box-domain'
                    options={dominId}
                    onChange={handleDomainSelect}
                    sx={{width: 300}}
                    renderInput={(params) => (
                      <TextField {...params} label='Domain' />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                {integration_type == 'customCurl' ? (
                  <CustomForm setCurlCode={setCurlCode} />
                ) : integration_type == 'trackbox' ? (
                  <TrackboxForm
                    handleChangeTrackboxData={handleChangeTrackboxData}
                  />
                ) : integration_type == 'customUrl' ? (
                  <DynamicFormFromURL
                    setUrl={setUrl}
                    url={urlCode}
                    setHttpMethod={setHttpMethod}
                    httpMethod={httpMethod}
                  />
                ) : null}
              </Grid>
            </Grid>

            <Box mt={3}>
              {isAdding ? (
                <LoadingButton loading variant='outlined'>
                  Add Customer
                </LoadingButton>
              ) : (
                <Button type='submit' variant='contained' color='primary'>
                  Add Customer
                </Button>
              )}
            </Box>
          </CardContent>
          <ConfirmModal
            handleClose={handleCloseModal}
            open={openConfirmModal}
          />
        </Card>
      </AppAnimate>
    </form>
  );
};
AddClientUI.propTypes = {
  description: PropTypes.node,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isAdding: PropTypes.bool.isRequired,
  setIntegration_type: PropTypes.func.isRequired,
  integration_type: PropTypes.string.isRequired,
  setCurlCode: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
  urlCode: PropTypes.string.isRequired,
  setHttpMethod: PropTypes.func.isRequired,
  httpMethod: PropTypes.string.isRequired,
  dominId: PropTypes.array.isRequired,
  handleDomainSelect: PropTypes.func.isRequired,
  handleChangeTrackboxData: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default AddClientUI;
