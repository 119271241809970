import {Autocomplete, Box, Button, Modal, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Matrix from '../Matrix';
import ExportToExcelButton from '@crema/core/AppExportExeclFile';
import LoadingButtons from 'pages/muiComponents/inputs/Buttons/LoadingButtons';
// import {green} from '@mui/material/colors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  minHeight: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UploadModal = ({
  open,
  handleClose,
  onSubmit,
  dominId,
  allStatusCount,
  selecteCustomer,
  doneStatusCount,
  errorStatusCount,
  domainSelected,
  handleDomainChange,
  affiliateId,
  onDropDownChange,
  onDropDownSearch,
  errorData,
  upLoadingData,
  handleClear,
}) => {
  return (
    <>
      <Modal
        open={open}
        aria-labelledby='parent-modal-title'
        aria-describedby='parent-modal-description'
      >
        <Box sx={{...style}}>
          <h2 id='parent-modal-title'>Choose Domain ...</h2>
          <Box m={2}>
            <Autocomplete
              fullWidth
              disablePortal
              id='combo-box-demo'
              options={dominId}
              onChange={handleDomainChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Client'
                  onChange={onDropDownSearch}
                />
              )}
            />
          </Box>
          {domainSelected && (
            <>
              <h2 id='parent-modal-title'>Choose AI ...</h2>
              <Box m={2}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id='combo-box-demo'
                  options={affiliateId}
                  onChange={onDropDownChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Client'
                      onChange={onDropDownSearch}
                    />
                  )}
                />
              </Box>
              {' '}
            </>
          )}

          {allStatusCount > 0 && (
            <Box m={2}>
              <Matrix
                allStatusCount={allStatusCount}
                doneStatusCount={doneStatusCount}
                errorStatusCount={errorStatusCount}
                title='API Sending'
              />
              {errorStatusCount > 0 && (
                <ExportToExcelButton
                  fileName={`error-${new Date().toString()}`}
                  json_data={errorData}
                />
              )}
            </Box>
          )}
          {!upLoadingData && allStatusCount == 0 && (
            <>
              {selecteCustomer && domainSelected ? (
                <Box m={2} style={{display: 'inline'}}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    Send
                  </Button>
                </Box>
              ) : (
                ''
              )}
              <Box m={2} style={{display: 'inline'}}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
          {!upLoadingData && allStatusCount > 0 && (
            <Box m={2} style={{display: 'inline'}}>
              <Button
                variant='outlined'
                onClick={() => {
                  handleClose();
                  handleClear();
                }}
              >
                Done
              </Button>
            </Box>
          )}
          {upLoadingData && (
            <Box m={2} style={{display: 'inline'}}>
              <LoadingButtons loading variant='outlined'>
                Send
              </LoadingButtons>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};
UploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
  allStatusCount: PropTypes.number.isRequired,
  doneStatusCount: PropTypes.number.isRequired,
  errorStatusCount: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  affiliateId: PropTypes.array.isRequired,
  dominId: PropTypes.array.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onDropDownSearch: PropTypes.func.isRequired,
  errorData: PropTypes.any.isRequired,
  upLoadingData: PropTypes.bool.isRequired,
  selecteCustomer: PropTypes.bool.isRequired,
  domainSelected: PropTypes.bool.isRequired,
  handleClear: PropTypes.func.isRequired,
};
export default UploadModal;
