import {useState, useEffect} from 'react';
import AddClientUI from './AddClientUI';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {AppInfoView} from '@crema';
import {useDispatch} from 'react-redux';
import {fetchError} from 'redux/actions';
import {isHttpValid} from 'utils';

const AddClient = () => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [formData, updateFormData] = useState();
  const [formTrackbooxData, updateFormTrackbooxData] = useState();
  const [urlCode, setUrl] = useState('');
  const [curlCode, setCurl] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET'); // State to store the HTTP method
  const [dominId, setDominId] = useState([]);
  const [integration_type, setIntegration_type] = useState('trackbox');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  // Fetch domains when the component mounts
  useEffect(() => {
    const getData = setTimeout(() => {
      console.log('start ftech domains');

      getDomins(10, 0);
    }, 1000);
    return () => clearTimeout(getData);
  }, []);
  const setCurlCode = (e) => {
    setCurl(e.target.value);
  };

  const handleCloseModal = () => {
    setOpenConfirmModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let domainKey = selectedDomain.key;
    let additional_data_integration =
      integration_type == 'customCurl'
        ? {curl: curlCode}
        : integration_type == 'trackbox'
        ? formTrackbooxData
        : integration_type == 'customUrl'
        ? {url: urlCode, method: httpMethod}
        : null;
    if (
      integration_type != 'customCurl' &&
      integration_type != 'customUrl' &&
      !isHttpValid(formData.integration_url)
    ) {
      dispatch(fetchError('url not valid'));
      return;
    }
    setIsAdding(true);
    jwtAxios
      .post('customer', {
        user_name: formData.user_name,
        integration_url: formData.integration_url,
        integration_type,
        additional_data_integration,
        domainKey,
      })
      .then(() => {
        setOpenConfirmModal(true);
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleChangeTrackboxData = (e) => {
    updateFormTrackbooxData({
      ...formTrackbooxData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleDomainSelect = (event, value) => {
    setSelectedDomain(value);
  };

  const getDomins = (pageSize, pageNumber) => {
    const url = `domain/search?pageSize=${pageSize}&pageNumber=${pageNumber}&term=`;
    jwtAxios
      .get(url)
      .then((e) => {
        setDominId(
          e.data.data.map((item) => {
            console.log(item);

            return {
              label: `${item.domain_name}`,
              key: item.id,
            };
          }),
        );
      })
      .catch((e) => {
        dispatch(fetchError(e.message));
      });
    console.log('finish fetch');
  };

  return (
    <>
      <AppInfoView />
      <AddClientUI
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isAdding={isAdding}
        dominId={dominId}
        setIntegration_type={setIntegration_type}
        integration_type={integration_type}
        setCurlCode={setCurlCode}
        setUrl={setUrl}
        setHttpMethod={setHttpMethod}
        urlCode={urlCode}
        httpMethod={httpMethod}
        handleDomainSelect={handleDomainSelect}
        handleChangeTrackboxData={handleChangeTrackboxData}
        handleCloseModal={handleCloseModal}
        openConfirmModal={openConfirmModal}
      />
    </>
  );
};

export default AddClient;
