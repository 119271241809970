import {fetchEventSource} from '@microsoft/fetch-event-source';
import axios from 'axios';

// Set the base URL for axios globally (this will remain static)
const jwtAxios = axios.create({
  baseURL: 'https://be.blackdawn.info/', // YOUR_API_URL HERE (this will remain unchanged)
  headers: {
    'Content-Type': 'application/json',
  },
});

jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }
    return Promise.reject(err);
  },
);

// Function to set the authorization token for axios
export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

// Function to handle Server-Sent Events (SSE) with dynamic baseUrl support
export const sseRequest = async (path, options) => {
  // Set headers for the request, with dynamic content-type support
  let headers = options.contentType
    ? {
        Authorization: jwtAxios.defaults.headers.common['Authorization'],
        Accept: 'text/event-stream',
        'content-type': options.contentType,
      }
    : {
        Authorization: jwtAxios.defaults.headers.common['Authorization'],
        Accept: 'text/event-stream',
      };

  // Use dynamic baseUrl if provided in options, otherwise use the default base URL
  const sseBaseUrl = options.baseUrl || 'https://be.blackdawn.info/'; // default URL if none provided

  // Perform the fetchEventSource call with the dynamic URL
  await fetchEventSource(`${sseBaseUrl}/${path}`, {
    method: 'POST',
    headers: headers,
    body: options.body,
    onmessage: options.onmessage,
    onopen: options.onopen,
    keepalive: true,
    openWhenHidden: true,
    window,
    signal: AbortSignal.timeout(10000000), // Set timeout
    onclose: () => {
      options.onclose();
    },
    onerror: (err) => {
      options.onerror(err);
      throw err; // Rethrow error to stop the operation
    },
  });
};

// Export jwtAxios for other use cases
export default jwtAxios;
