import React, {useState} from 'react';
import {Card, Grid, Typography} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CodeIcon from '@mui/icons-material/Code';
import Highlight, {defaultProps} from 'prism-react-renderer';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import AppScrollbar from '../../../../@crema/core/AppScrollbar';
import {highlightTheme} from './highlightTheme';
import Box from '@mui/material/Box';
import AppAnimate from '../../../../@crema/core/AppAnimate';
import {Fonts} from '../../../../shared/constants/AppEnums';
import UploadButton from '../BasicDropZone/UploadButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const CAppComponentCard = ({
  title,
  maxHeight,
  description,
  children,
  domainSelected,
  handleDomainChange,
  source,
  noScrollbar,
  functionToRun,
  sendDataLoading,
  fileSelected,
  affiliateId,
  dominId,
  onDropDownChange,
  onDropDownSearch,
}) => {
  const [viewSource, setToggleViewSource] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  console.log(domainSelected);
  console.log(fileSelected);

  const handleDomainSelect = (event, value) => {
    setSelectedDomain(value);
    handleDomainChange(event, value);
  };

  const handleClientSelect = (event, value) => {
    setSelectedClient(value);
    onDropDownChange(event, value);
  };

  const isUploadButtonVisible =
    selectedDomain && selectedClient && fileSelected;

  return (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <Card>
        <CardHeader
          sx={{
            py: 4,
            pb: 1,
            px: 5,
            display: 'flex',
            alignItems: 'center',
            minHeight: 5,
            boxSizing: 'border-box',
            '& .MuiTypography-h5': {
              fontSize: 14,
              fontWeight: Fonts.BOLD,
              marginBottom: 0.25,
            },
          }}
          title={title}
          subheader={description}
          root={{
            subheader: {
              fontSize: 13,
            },
          }}
          action={
            source ? (
              <Box>
                <IconButton
                  aria-label='view code'
                  onClick={() => {
                    if (animation) {
                      setAnimation(!animation);
                      setTimeout(() => setToggleViewSource(!viewSource), 400);
                    } else {
                      setAnimation(!animation);
                      setToggleViewSource(!viewSource);
                    }
                  }}
                  size='large'
                >
                  <CodeIcon />
                </IconButton>
              </Box>
            ) : null
          }
        />

        <CardContent sx={{px: 4, pt: 0, minHeight: '100rem'}}>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12} sm='auto'>
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography variant='subtitle2' component='div'>
                    Send using
                  </Typography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    disablePortal
                    id='combo-box-domain'
                    options={dominId}
                    onChange={handleDomainSelect}
                    sx={{width: 300}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Domain'
                        onChange={onDropDownSearch}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {selectedDomain && (
              <Grid item xs={12} sm='auto'>
                <Grid container alignItems='center' spacing={1}>
                  <Grid item>
                    <Typography variant='subtitle2' component='div'>
                      AI
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      disablePortal
                      id='combo-box-affiliate'
                      options={affiliateId}
                      onChange={handleClientSelect}
                      sx={{width: 300}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Client'
                          onChange={onDropDownSearch}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Collapse in={animation}>
            {viewSource ? (
              <AppScrollbar
                sx={{
                  borderRadius: 3,
                  background: '#333333',
                }}
              >
                <Highlight
                  {...defaultProps}
                  code={source}
                  language='jsx'
                  theme={highlightTheme}
                >
                  {({style, tokens, getLineProps, getTokenProps}) => (
                    <pre
                      style={{
                        ...style,
                        maxHeight: 1000,
                        borderRadius: 8,
                        padding: 12,
                      }}
                    >
                      {tokens.map((line, i) => (
                        <Box
                          key={'line-' + i}
                          {...getLineProps({line, key: i})}
                        >
                          {line.map((token, key) => (
                            <span
                              key={'token-' + key}
                              {...getTokenProps({token, key})}
                            />
                          ))}
                        </Box>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </AppScrollbar>
            ) : null}
          </Collapse>

          {noScrollbar ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
                backgroundColor: (theme) => theme.palette.background.default,
              }}
            >
              {children}
            </Box>
          ) : (
            <AppScrollbar
              sx={{mt: 2, p: 4, borderRadius: 3, maxHeight: maxHeight}}
            ></AppScrollbar>
          )}

          {isUploadButtonVisible && (
            <UploadButton
              isLoading={sendDataLoading}
              functionToRun={functionToRun}
            />
          )}
          <br />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {children}
          </Box>
        </CardContent>
      </Card>
    </AppAnimate>
  );
};

export default CAppComponentCard;

CAppComponentCard.defaultProps = {
  description: '',
  maxHeight: 500,
};

CAppComponentCard.propTypes = {
  children: PropTypes.any.isRequired,
  source: PropTypes.any,
  title: PropTypes.node.isRequired,
  maxHeight: PropTypes.number,
  description: PropTypes.node,
  noScrollbar: PropTypes.bool,
  fileSelected: PropTypes.bool.isRequired,
  dominId: PropTypes.array.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
  domainSelected: PropTypes.array.isRequired,
  functionToRun: PropTypes.func.isRequired,
  sendDataLoading: PropTypes.bool.isRequired,
  affiliateId: PropTypes.array.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onDropDownSearch: PropTypes.func.isRequired,
};
