import React from 'react';
import AppComponentHeader from '../../../@crema/core/AppComponentHeader';
import AppGridContainer from '../../../@crema/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import RowEditControlGrid from './DataController/RowEditControlGrid';
import PropsType from 'prop-types';
import {Box, Button, Paper} from '@mui/material';
import UploadModal from './UploadModal/UploadModal';

const DataUI = ({
  dataToShow,
  pageSize,
  setPageSize,
  handleCloseModal,
  pageNumber,
  setPageNumber,
  rowCount,
  onFilterChange,
  onEditCommit,
  setPatchEdited,
  gettingData,
  handleOnSelect,
  selected,
  allStatusCount,
  doneStatusCount,
  errorStatusCount,
  handleDomainChange,
  setShowUploadModal,
  showUploadModal,
  dominId,
  onUploadSubmitting,
  onDropDownChange,
  onDropDownSearch,
  affiliateId,
  errorData,
  selecteCustomer,
  domainSelected,
  upLoadingData,
  handleClear,
  userRole,
  deleteData,
}) => {
  return (
    <>
      <AppComponentHeader title='Data Table' />
      <AppGridContainer>
        <Grid item xs={12}>
          {selected.length > 0 && userRole.filter((e) => e == 'admin')[0] && (
            <Box>
              <Grid container>
                <Box m={4}>
                  <Button
                    variant='contained'
                    onClick={() => setShowUploadModal(true)}
                  >
                    Upload Data
                  </Button>
                </Box>
                <Box m={4}>
                  <Button variant='contained' onClick={() => deleteData()}>
                    Delete
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}

          <Paper>
            <RowEditControlGrid
              userRole={userRole}
              dataToShow={dataToShow}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              rowCount={rowCount}
              onFilterChange={onFilterChange}
              onEditCommit={onEditCommit}
              setPatchEdited={setPatchEdited}
              gettingData={gettingData}
              handleOnSelect={handleOnSelect}
            />
          </Paper>
        </Grid>

        <UploadModal
          open={showUploadModal}
          selecteCustomer={selecteCustomer}
          domainSelected={domainSelected}
          onSubmit={onUploadSubmitting}
          allStatusCount={allStatusCount}
          doneStatusCount={doneStatusCount}
          handleDomainChange={handleDomainChange}
          dominId={dominId}
          errorStatusCount={errorStatusCount}
          handleClose={handleCloseModal}
          onDropDownChange={onDropDownChange}
          onDropDownSearch={onDropDownSearch}
          affiliateId={affiliateId}
          errorData={errorData}
          upLoadingData={upLoadingData}
          handleClear={handleClear}
        />
      </AppGridContainer>
    </>
  );
};
DataUI.propTypes = {
  dataToShow: PropsType.array.isRequired,
  pageSize: PropsType.number.isRequired,
  setPageSize: PropsType.func.isRequired,
  pageNumber: PropsType.number.isRequired,
  setPageNumber: PropsType.func.isRequired,
  rowCount: PropsType.number.isRequired,
  onFilterChange: PropsType.func.isRequired,
  onEditCommit: PropsType.func.isRequired,
  setPatchEdited: PropsType.func.isRequired,
  handleCloseModal: PropsType.func.isRequired,

  handleDomainChange: PropsType.func.isRequired,
  gettingData: PropsType.bool.isRequired,
  selecteCustomer: PropsType.bool.isRequired,
  domainSelected: PropsType.bool.isRequired,
  dominId: PropsType.array.isRequired,
  handleOnSelect: PropsType.func.isRequired,
  selected: PropsType.array.isRequired,
  allStatusCount: PropsType.number.isRequired,
  doneStatusCount: PropsType.number.isRequired,
  errorStatusCount: PropsType.number.isRequired,
  setShowUploadModal: PropsType.func.isRequired,
  showUploadModal: PropsType.bool.isRequired,
  onUploadSubmitting: PropsType.func.isRequired,
  onDropDownChange: PropsType.func.isRequired,
  onDropDownSearch: PropsType.func.isRequired,
  affiliateId: PropsType.array.isRequired,
  errorData: PropsType.any.isRequired,
  upLoadingData: PropsType.bool.isRequired,
  handleClear: PropsType.func.isRequired,
  userRole: PropsType.any.isRequired,
  deleteData: PropsType.func.isRequired,
};
export default DataUI;
